body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, 'Segoe UI', Tahoma, Verdana, sans-serif;
  background: linear-gradient(135deg, #3b6691, #727c81);
  color: #09042b;
  overflow: hidden;
  height: 100%;
}

/* HEADER */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.header-title {
  margin-right: auto;
  font-size: 15px;
  color: white;
}

.header-nav {
  margin-left: auto; 
}

.nav-links {
  display: flex;
  justify-content: flex-end; 
}

.nav-links p {
  margin: 0 50px;
}

.nav-link {
  text-decoration: none;
  color: white;
  font-size: 14px;
  transition: color 0.3s ease;
}

.nav-link.active {
  color: #4481be;
  background-color: white;
  padding: 5px 15px;
  border-radius: 15px;
}

/* HOME */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: transparent;
}

.login-content {
  width: 80%;
  max-width: 800px;
  height: 50%;
  min-height: 350px;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.9); 
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: -50px;
}

.image-container {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 8rem;
}
.langchainimg {
  width: 200px;
  margin-bottom: 20px;
}
.nylasimg {
  width: 150px;
  margin-bottom: 20px;
}

.home-text {
  text-align: center;
}

.home-text p {
  color: #09042b;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 40px;
}

.connect-nylas-button {
  width: 30%;
  padding: 20px;
  border: none;
  border-radius: 25px;
  background-color: #4481be;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.connect-nylas-button:hover {
  background-color: #4481be;
}


/* EMAIL  */
.email-container {
  display: flex;
  gap: 20px;
  margin: 70px 50px 50px 50px;
  height: 95vh;  
  overflow: hidden;
}

.email-list-container, .email-content-container {
  flex: 1;
  overflow-y: auto; 
  margin: 0 10px;
  background-color: rgba(255, 255, 255, 0.9); 
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border-radius: 25px;
  height: 80%;
}
.email-item, .email-content, .email-content-placeholder {
  border-bottom: 1px solid #eaeaea; 
  padding: 20px;
  transition: background-color 0.2s ease-in-out;
}
.email-item:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}
.email-item h2, .email-content h3 {
  color: #1a1a1a;
  font-size: 20px; 
  margin: 0 0 10px 0; 
}
.email-item p, .email-content p {
  color: #09042b;
  font-size: 16px; 
  margin: 5px 0;
}
.email-content {
  background: #f4f7f6;
  border-radius: 5px;
}
.email-content-placeholder {
  text-align: center;
  color: #999;
}
.email-item.active {
  background-color: #e6f7ff;
  border-left: 4px solid #4481be;
  padding-left: 16px; 
}



/* CALENDAR */
.calendar-container {
  display: flex;
  gap: 20px;
  margin: 70px 50px 50px 50px;
  height: 95vh;
  overflow: hidden;
}

.upcoming-event-container, .event-content-container {
  flex: 1;
  overflow-y: auto;
  margin: 0 10px;
  background-color: rgba(255, 255, 255, 0.9); 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  height: 80%;
}
.event-item, .event-content, .event-content-placeholder {
  border-bottom: 1px solid #eaeaea;
  padding: 20px;
  transition: background-color 0.2s ease-in-out;
}

.event-item:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.event-item h2, .event-content h3 {
  color: #1a1a1a;
  font-size: 20px;
  margin: 0 0 10px 0;
}

.event-item p, .event-content p {
  color: #666;
  font-size: 16px;
  margin: 5px 0;
}

.event-content {
  background: #f4f7f6;
  border-radius: 5px;
}

.event-content-placeholder {
  text-align: center;
  color: #999;
}








/* PHONE */
@media screen and (max-width: 420px) {  
  .header {
    padding: 2px 10px;
    background-color: transparent; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  }
  .header-title h2{
    font-size: 16px;
    color: white;
  }
  .nav-links a{
    font-size: 12px;
    color: white;
  }
  .nav-links p {
    margin: 0 20px;
  }
  .nav-link.active {
    background-color: white;
    color: #4481be;
    padding: 5px 10px;
    border-radius: 10px;
  }
  
  /* HOME */
  .login-content {
    padding: 20px;
    height: 60%;
    margin-top: -120px;
  }
  .image-container {
    flex-direction: column; 
    gap: 1rem; 
  }
  .langchainimg {
    width: 150px; 
  }
  .nylasimg {
    width: 110px; 
  }
  .home-text p {
    font-size: 14px;
  }
  .connect-nylas-button {
    width: 50%; 
    font-size: 14px; 
    padding: 10px;
    margin-bottom: 30px;
  }
  
  /* EMAIL & CALENDAR*/
  .email-container, .calendar-container {
    flex-direction: column; 
    margin: 20px 20px; 
    height: 77vh;
  }
  .email-list-container, .upcoming-event-container{
    margin: 0; 
    height: 50%; 
  }
  .email-content-container, .event-content-container {
    margin: 0;
    height: 50%;
  }

  .email-item, .email-content, .email-content-placeholder {
    padding: 12px;
  }
  .email-item h2, .email-content h3 {
    font-size: 15px; 
    margin: 0 0 6px 0; 
  }
  .email-item p, .email-content p {
    font-size: 12px; 
    margin: 2px 0;
  }
  .event-item, .event-content, .event-content-placeholder {
    padding: 12px;
  }
  .event-item h2, .event-content h3 {
    font-size: 15px;
    margin: 0 0 6px 0;
  }
  .event-item p, .event-content p {
    font-size: 12px;
    margin: 2px 0;
  }

  

}
